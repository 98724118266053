// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-service-js": () => import("../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-post-js": () => import("../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-project-js": () => import("../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-partners-js": () => import("../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-pdfs-js": () => import("../src/pages/pdfs.js" /* webpackChunkName: "component---src-pages-pdfs-js" */),
  "component---src-pages-projects-js": () => import("../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-hospitality-js": () => import("../src/pages/projects/hospitality.js" /* webpackChunkName: "component---src-pages-projects-hospitality-js" */),
  "component---src-pages-projects-residential-offices-js": () => import("../src/pages/projects/residential-offices.js" /* webpackChunkName: "component---src-pages-projects-residential-offices-js" */),
  "component---src-pages-projects-showrooms-industrial-js": () => import("../src/pages/projects/showrooms-industrial.js" /* webpackChunkName: "component---src-pages-projects-showrooms-industrial-js" */),
  "component---src-pages-projects-warehouse-villas-js": () => import("../src/pages/projects/warehouse-villas.js" /* webpackChunkName: "component---src-pages-projects-warehouse-villas-js" */),
  "component---src-pages-services-js": () => import("../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

